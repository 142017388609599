<template>
	<div class="reset-password-form">
		<h3 >Réinitialiser votre mot de passe</h3>
		<input v-if="success==false" type="password" id="password_reset" v-model="password_reset" placeholder="Votre nouveau mot de passe" /><br />
		<input v-if="success==false" type="password" id="password_verification" v-model="password_verification" placeholder="Répétez nouveau mot de passe" /><br />
		<input type="hidden" id="token" :value="token" />
		<center v-if="success==false"><button id="reset_pass_button" @click="resetPassword()">Réinitialiser mon mot de passe</button></center>
		
		<div id="error-message-reset">{{errorMessage}}</div>
		<div id="success-message-reset"><span v-html="successMessage"></span></div>
    
  </div>
  <Footer :language="language" :displayModalMenu="displayModalMenu" />
</template>

<script>
import Footer from '../components/layout/Footer.vue'
import {APIRequest,Woocommerce, Auth}  from '@simb2s/senseye-sdk';
import sendinblue from 'sendinblue'
import fbq from 'fbq'

export default {
  name: 'ResetPassword',
  components: {
    Footer
  },
  data() {
    return {
        token:null,
        password_verification:null,
        password_reset:null,
        success:false,
        errorMessage:'',
        successMessage:'',
    }
  },
  props:["displayModalMenu","language"],
  methods:{
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
		},
    async resetPassword()
    {
      if(this.password_reset==this.password_verification)
      {
        try {
          let Parameters = {};
          Parameters.password = this.password_reset;
          let user=this.parseJwt(this.token);
          Parameters.username = user.username;
        
          await Auth.reset_password(Parameters);
          this.success=true
          this.successMessage="Votre mot de passe a bien été réinitialisé !<br /><a href='/mon-compte'>Se connecter</a>";
        } catch (error) {
          this.errorMessage='Le lien a expiré !';
        }
      }
      else
      {
        alert('les deux mots de passe ne sont pas identiques !');
      }
    }
  },
  async mounted()
  {

    this.token=this.$route.params.token
    localStorage.token_senseye_auth=this.token
  }
  
}
</script>
<style>

</style>